<template>
  <div>

    <section class="top">
      <div class="container">
        <a href="/#/login" title="立即登录"><i></i><p>立即登录</p></a>
        <div class="logo"><span></span><i></i></div>
      </div>
    </section>
    <section class="main">
      <div class="container">
        <div class="title"><span class="reset"></span></div>
        <div class="box">
          <div class="boxname"><span>输入新密码</span></div>
          <div class="boxinfo">
            <div class="row">
              <div class="name">输入新密码<em>*</em></div>
              <div class="inputbox"><input type="password" value="" /></div>
              <div class="tips"></div>
            </div>
            <div class="row">
              <div class="name">确认新密码<em>*</em></div>
              <div class="inputbox"><input type="password" value="" /></div>
              <div class="tips"></div>
            </div>
          </div>
        </div>
        <div class="btns">
          <a href="javascript:submit();" class="submit">确定</a><a href="javascript:history.go(-1);" class="cancel">取消</a>
        </div>
      </div>
    </section>
    <footerUl></footerUl>
  </div>
</template>

<script>
  export default {
    name: "resetPassword",
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>
  @import "../assets/css/findPassword.css";
</style>
